<template>
  <div class="notice">
    <!-- 添加备忘录页面 -->
    <Header back="返回" title="备忘录" index="首页" work_bench="工作台" beforeTitle="备忘录" />
    <!-- 添加备忘录内容 -->
    <div class="noticeText">
      <div class="textHead">
        <h1 v-if="!memoform.id">添加备忘录</h1>
        <h1 v-if="memoform.id">编辑备忘录</h1>
        <div class="textName">
          <span id="time">添加时间：{{ seleDate }}</span>
        </div>
      </div>
      <el-form ref="formRule" :model="memoform">
        <el-form-item style="width: 100%" prop="content">
          <el-input
            v-model="memoform.content"
            type="textarea"
            :autosize="{ minRows: 15, maxRows: 25 }"
            placeholder="请输入备忘录内容"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="foot_TJ">
        <el-button :loading="loadingBtn" type="primary" @click="memoAdd()">确定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      loadingBtn: false,
      seleDate: "",
      memoform: {
        id: "",
        content: "",
      },
      formRule: {
        memotext: [],
      },
    };
  },
  created () {
    if (this.$route.query.item) {
      this.memoform = this.$route.query.item;
      console.log("编辑跳转");
    } else {
      console.log("新增跳转");
    }
  },
  mounted () {
    this.getCurrentTime();
  },
  methods: {
    returnPage () {
      this.$router.go(-1);
    },
    getCurrentTime () {
      //获取当前时间
      var _this = this;
      let yy = new Date().getFullYear();
      let mm = new Date().getMonth() + 1;
      let dd = new Date().getDate();
      // let hh = new Date().getHours();
      // let mf =
      //   new Date().getMinutes() < 10
      //     ? "0" + new Date().getMinutes()
      //     : new Date().getMinutes();
      // let ss =
      //   new Date().getSeconds() < 10
      //     ? "0" + new Date().getSeconds()
      //     : new Date().getSeconds();
      _this.gettime = yy + "-" + mm + "-" + dd;
      this.seleDate = _this.gettime;
      // console.log(_this.gettime, this.seleDate);
    },

    memoAdd () {
      if (this.memoform.id) {
        this.$refs.formRule.validate((vaild) => {
          if (vaild) {
            this.loadingBtn = true
            this.axios
              .post("/api/memorandum/store", {
                id: this.memoform.id,
                content: this.memoform.content,
              })
              .then((res) => {
                this.loadingBtn = false
                this.$router.go(-1);
                this.$message({
                  type: "success",
                  message: "编辑成功!",
                });
              });
          }
        });
      } else {
        this.$refs.formRule.validate((vaild) => {
          if (vaild) {
            this.loadingBtn = true
            this.axios
              .post("/api/memorandum/store", this.memoform)
              .then((res) => {
                this.loadingBtn = false
                this.$router.push("/memo/memoMore");
                this.$message({
                  type: "success",
                  message: "保存成功!",
                });
              });
          }
        });
      }
    },
  },
};
</script>

<style scoped>
.notice {
  width: 100%;
  height: 100%;
}
.notice .noticeText {
  width: 95%;
  height: 100%;
  background: #fff;
  padding: 40px;
  margin-top: 15px;
}
.notice .noticeText .textHead h1 {
  font-size: 20px;
  font-family: Microsoft YaHei, Microsoft YaHei-Bold;
  font-weight: 700;
  color: #000000;
}
.notice .noticeText .textName span {
  font-size: 14px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  color: #a6abc7;
  display: inline-block;
  margin: 14px 90px 22px 0;
}
</style>
